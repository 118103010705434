import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

const style = theme => definitions => ({
  holder: {
    fontSize: "1.48rem",
    height: "32px",
    textAlign: "center",
    paddingTop: "2px"
  },
  icon: {
    color: theme.palette.get("primary")
  }
});

const PendingButton = props => {
  const classes = buildClasses(style, props);
  return (
    <div className={classes.holder}>
      <FontAwesomeIcon className={classes.icon} icon={faSpinner} spin />
    </div>
  );
};

export default PendingButton;
