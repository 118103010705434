import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import Logo from "../logo.svg";

import { Grid } from "@nodeme/grid-react";

const style = theme => definitions => ({
  wrapper: { display: "table", height: "100vh", width: "100%" },
  aligner: { display: "table-cell", verticalAlign: "middle" },
  block: {
    backgroundColor: "#fff",
    textAlign: "left",
    borderRadius: "4px"
  },
  caption: {
    fontSize: "1rem",
    fontWeight: 100,
    color: "#929292",
    margin: "0px 0px 0px 0px"
  },
  data: {
    fontSize: "3rem",
    fontWeight: 400,
    margin: "0px 0px 0px 0px"
  }
});

const Preview = props => {
  const classes = buildClasses(style, props);
  const state = props.state;
  if (state === null || state.page !== 1)
    return (
      <div className={classes.wrapper}>
        <div className={classes.aligner}>
          <img src={Logo} alt="artcare-logo" />
        </div>
      </div>
    );
  const values = state.values;
  return (
    <div className={classes.wrapper}>
      <div className={classes.aligner}>
        <div className={classes.block}>
          <Grid spacing={32} vertical>
            <div xs={10} sm={5}>
              <h5 className={classes.caption}>VORNAME</h5>
              <p className={classes.data}>{values.firstname || "-"}</p>
            </div>
            <div xs={10} sm={5}>
              <h5 className={classes.caption}>NACHNAME</h5>
              <p className={classes.data}>{values.lastname || "-"}</p>
            </div>
            <div xs={10} sm={5}>
              <h5 className={classes.caption}>EMAIL</h5>
              <p className={classes.data}>{values.email || "-"}</p>
            </div>
            <div xs={10} sm={5}>
              <h5 className={classes.caption}>TEL.</h5>
              <p className={classes.data}>{values.phone || "-"}</p>
            </div>
            <div xs={10}>
              <h5 className={classes.caption}>ADRESSE</h5>
              {!values.address && <p className={classes.data}>-</p>}
              {Boolean(values.address) && (
                <p className={classes.data}>
                  {values.address}, {values.plz} {values.city}
                </p>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

class PreviewWrapper extends React.Component {
  state = { storage: null };

  constructor(props) {
    super(props);
    this.localStorageUpdated = this.localStorageUpdated.bind(this);
  }
  componentDidMount() {
    window.addEventListener("storage", this.localStorageUpdated);
  }
  componentWillUnmount() {
    window.removeEventListener("storage", this.localStorageUpdated);
  }
  localStorageUpdated() {
    const registration = localStorage.getItem("registration");
    this.setState({ storage: JSON.parse(registration) });
  }

  render() {
    return <Preview state={this.state.storage} />;
  }
}

export default PreviewWrapper;
