import React, { Children } from "react";
import { buildClasses } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const style = theme => definitions => ({
  step: { textAlign: "center" },
  icon: {
    display: "inline-block",
    fontSize: "1.5rem",
    padding: "12px",
    borderRadius: "100px",
    color: "rgba(0, 0, 0, 0.4)",
    width: "50px !important",
    height: "50px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.get("default")
  },
  iconActive: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff"
  },
  stepText: {
    textAlign: "center",
    fontSize: "0.7rem",
    display: "none",
    color: theme.palette.get("grey"),
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  stepTextActive: {
    color: theme.palette.get("primary")
  },
  placeholder: {
    marginTop: "22px",
    borderTop: `2px dashed ${theme.palette.get("default")}`,
    width: "100%",
    display: "block"
  },
  placeholderActive: {
    borderTop: `2px dashed ${theme.palette.get("primary")}`
  }
});

const Stepper = props => {
  const classes = buildClasses(style, props);
  const { children, index } = props;
  return (
    <div>
      <div className={classes.head}>
        <Grid spacing={16} vertical flow="no-wrap row">
          {Children.toArray(children).reduce((accu, child, pos) => {
            const toReturn = [
              ...accu,
              <div
                key={`stepper-${pos}`}
                className={classes.step}
                xs={"50px"}
                sm={"90px"}
              >
                {child.props.icon && (
                  <FontAwesomeIcon
                    className={classNames([
                      classes.icon,
                      index >= pos && classes.iconActive
                    ])}
                    icon={child.props.icon}
                  />
                )}
                <div
                  className={classNames([
                    classes.stepText,
                    index >= pos && classes.stepTextActive
                  ])}
                >
                  {child.props.name}
                </div>
              </div>
            ];
            if (pos < children.length - 1)
              toReturn.push(
                <div
                  key={`stepper-placeholder-${pos}`}
                  className={classes.stepPlaceholder}
                  xs={"rest"}
                >
                  <span
                    className={classNames([
                      classes.placeholder,
                      index - 1 >= pos && classes.placeholderActive
                    ])}
                  />
                </div>
              );
            return toReturn;
          }, [])}
        </Grid>
      </div>
      <div className={classes.body}>
        {React.Children.map(children, (child, pos) => (
          <div style={{ display: index === pos ? "block" : "none" }}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
