import React, { createRef } from "react";
import Cloud from "@ypi/client-sdk";
import { Grid } from "@nodeme/grid-react";

export default class Browser extends React.PureComponent {
  _isMounted = false;
  _renderElement = () => "";

  constructor(props) {
    super(props);
    this.state = {
      search: null,
      elements: [],
      data: [],
      pending: false,
      error: false,
      index: 0,
      fullyDisplayed: false,
      fullyLoaded: false
    };

    this._renderElement = props.render;

    this.load = this.load.bind(this);
    this.saveSetState = this.saveSetState.bind(this);
    this.checkLazyLoad = this.checkLazyLoad.bind(this);
    this.applySearch = this.applySearch.bind(this);

    this.lastElement = createRef();
  }
  saveSetState(newState) {
    if (this._isMounted) this.setState(newState);
  }
  componentDidMount() {
    this._isMounted = true;
    this.checkLazyLoad();
    window.addEventListener("scroll", this.checkLazyLoad, true);
  }
  componentDidUpdate() {
    this.checkLazyLoad();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkLazyLoad);
    this._isMounted = false;
  }
  checkLazyLoad() {
    const searchApplied =
      (this.props.search ? JSON.stringify(this.props.search) : null) ===
      this.state.search;

    if (this.lastElement.current) {
      const checkerPos = this.lastElement.current.offsetTop;
      const windowBottom = window.pageYOffset + window.innerHeight;

      if (
        !this.state.pending &&
        ((!this.state.fullyDisplayed && checkerPos < windowBottom) ||
          !searchApplied)
      ) {
        this.load();
      }
    }
  }
  async load() {
    if (!this.state.pending)
      this.saveSetState({ ...this.state, pending: true });
    else return;

    const { model, query, projection, sorting, limit } = this.props;
    const lastElement = this.state.data.length
      ? this.state.data[this.state.data.length - 1].id
      : false;

    const searchApplied =
      (this.props.search ? JSON.stringify(this.props.search) : null) ===
      this.state.search;

    try {
      let toShow = [];
      let toAdd = [];

      let loaded = false;
      if (
        !this.state.fullyLoaded &&
        this.state.index >= this.state.data.length
      ) {
        const result = await Cloud.models[model].get(
          query,
          projection,
          sorting,
          limit,
          lastElement
        );
        toAdd = result.data;
        loaded = true;
      }
      let index = this.state.index;

      if (searchApplied) {
        toShow = [
          ...this.state.elements,
          ...this.applySearch(
            [...this.state.data, ...toAdd].slice(
              index,
              index + this.props.limit
            )
          )
        ];
        index = index + this.props.limit;
      } else {
        toShow = this.applySearch(
          [...this.state.data, ...toAdd].slice(0, this.props.limit)
        );
        index = this.props.limit;
      }

      this.setState({
        ...this.state,
        pending: false,
        error: false,
        search: this.props.search ? JSON.stringify(this.props.search) : null,
        data: [...this.state.data, ...toAdd],
        elements: toShow,
        index,
        fullyDisplayed: index > [...this.state.data, ...toAdd].length,
        fullyLoaded: loaded ? !toAdd.length : this.state.fullyLoaded
      });
    } catch (err) {
      this.setState({
        ...this.state,
        pending: false,
        error: true
      });
    }
  }
  applySearch(data) {
    const { search } = this.props;
    return Boolean(search)
      ? data.filter((item, index) => {
          return Boolean(
            Object.keys(search).find(
              field =>
                (item[field] || "").toLowerCase().indexOf(search[field]) > -1
            )
          );
        })
      : data;
  }

  render() {
    const { error, pending, elements } = this.state;
    const searchApplied =
      (this.props.search ? JSON.stringify(this.props.search) : null) ===
      this.state.search;

    return (
      <div style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
        <Grid spacing={16} vertical>
          {elements.map((ele, i) =>
            this._renderElement(ele, i, elements, elements => {
              this.setState({ ...this.state, elements });
            })
          )}
          <div ref={this.lastElement} style={{ height: "1px" }}></div>
          {!pending && !error && !searchApplied && (
            <div style={{ textAlign: "center" }}>Suche...</div>
          )}
          {pending && (
            <div style={{ textAlign: "center" }}>Lade Tickets...</div>
          )}
          {error && (
            <div style={{ textAlign: "center" }}>
              Ein Fehler ist aufgetreten!
            </div>
          )}
        </Grid>
      </div>
    );
  }
}
