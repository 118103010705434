import React, { useState } from "react";

import Cloud from "@ypi/client-sdk";

import Stepper from "./Stepper";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import { faGavel } from "@fortawesome/pro-light-svg-icons/faGavel";

import { Grid } from "@nodeme/grid-react";

import Form from "@nodeme/forms-react/Form";
import Text from "@nodeme/forms-react/Text";
import Number from "@nodeme/forms-react/Number";
import Select from "@nodeme/forms-react/Select";
import Button from "@nodeme/forms-react/Button";
import Radio from "@nodeme/forms-react/Radio";
import RadioItem from "@nodeme/forms-react/RadioItem";
import PendingButton from "./PendingButton";

import { buildClasses } from "@nodeme/jss-react";

import QrReader from "react-qr-scanner";
import List from "./List";

function qrError(error) {
  console.log(error);
}
function setTicket(values, registration, setRegistration) {
  let toSet = {
    ...registration,
    id: values.id,
    manualTendererId: null,
    tendererId: values.tendererId,
    page: 1,
    pending: false,
    mode: null
  };

  [
    "salutation",
    "firstname",
    "lastname",
    "email",
    "phone",
    "address",
    "plz",
    "city",
    "accompany"
  ].forEach(field => {
    if (field !== "id" && field !== "tendererId")
      toSet.values[field] = values[field];

    if (!Boolean(values[field]) && values[field] !== 0)
      toSet.errors[field] = "notSet";
  });

  setRegistration(toSet);
}
function qrScan(registration, setRegistration, setMode, offline = false) {
  return async data => {
    if (data) {
      setMode("pending");

      try {
        const result = await Cloud.models.Registration.get(
          {
            id: data.substring(8),
            event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
          },
          [
            "id",
            "tendererId",
            "salutation",
            "firstname",
            "lastname",
            "email",
            "phone",
            "address",
            "plz",
            "city",
            "accompany"
          ],
          { f: "createdAt", d: "desc" },
          1
        );
        if (result.data === null) return setMode("notFound");
        setTicket(result.data, registration, setRegistration);
      } catch (err) {
        console.log(err);
        setMode("error");
      }
    }
  };
}
function qrScanTendererId(registration, setRegistration) {
  return async data => {
    if (data) {
      setRegistration({ ...registration, pending: true });

      try {
        await Cloud.models.Registration.update(
          { tendererId: data },
          {
            id: registration.id,
            event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
          }
        );
        setRegistration({
          ...registration,
          tendererId: data,
          pending: false,
          success: true
        });
      } catch (err) {
        setRegistration({ ...registration, pending: false, unkownError: true });
      }
    }
  };
}
async function deleteTendererId(registration, setRegistration) {
  setRegistration({ ...registration, tendererId: null, pending: true });

  try {
    await Cloud.models.Registration.update(
      { tendererId: null },
      {
        id: registration.id,
        event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
      }
    );
    setRegistration({
      ...registration,
      tendererId: null,
      pending: false
    });
  } catch (err) {
    setRegistration({
      ...registration,
      tendererId: null,
      pending: false,
      unkownError: true
    });
  }
}
async function save(state, setState, name) {
  setState({ ...state, pending: true });
  try {
    if (state.id) {
      await Cloud.models.Registration.update(
        { ...state.values, checkIn: name },
        { id: state.id, event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9" }
      );
      setState({ ...state, pending: false, page: 2 });
    } else {
      const result = await Cloud.models.Registration.add({
        event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9",
        ...state.values,
        type: "guest",
        checkIn: name
      });

      setState({
        ...state,
        id: result.data.id,
        pending: false,
        page: 2,
        errors: {}
      });
    }
  } catch (err) {
    if (err.name === "ValidationError")
      setState({ ...state, pending: false, errors: err.errors, page: 1 });
    else setState({ ...state, pending: false, page: 1 });
  }
}
const setValue = (field, state, setState) => value => {
  if (!state.pending)
    setState({
      ...state,
      values: {
        ...state.values,
        [field]:
          field === "email"
            ? value.toLowerCase()
            : field === "plz"
            ? value === ""
              ? null
              : parseInt(value)
            : value
      }
    });
};
const getErrorText = error => {
  if (!error) return "";
  switch (error) {
    case "notSet":
      return "ist ein Pflichtfeld.";
    case "notInt":
      return "Bitte gebe Sie eine Zahl ein.";
    default:
      return "hat einen unbekannten Fehler.";
  }
};
const getInitialState = () => ({
  page: 0,
  mode: null,
  id: null,
  tendererId: null,
  manuallTendererId: "",
  values: {
    salutation: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    plz: null,
    city: "",
    accompany: null
  },
  manuall: false,
  success: false,
  errors: {},
  pending: false,
  unkownError: false
});
const styles = theme => definitions => ({
  danger: {
    color: theme.palette.get("danger")
  }
});

export default function Registration(props) {
  const classes = buildClasses(styles);
  const [registration, setRegistrationState] = useState(getInitialState());
  const setRegistration = state => {
    if (window.localStorage)
      localStorage.setItem("registration", JSON.stringify(state));
    setRegistrationState(state);
  };
  const { values, errors, pending, mode } = registration;
  const setMode = to => setRegistration({ ...registration, mode: to });
  console.log(props.name);
  return (
    <Stepper index={registration.page}>
      <div name="Suchen" icon={faSearch}>
        <Form spacing={8}>
          <Grid justify="center" spacing={8}>
            <Button
              xs="90px"
              success
              onClick={e => {
                e.preventDefault();
                setRegistration({ ...registration, page: 1 });
              }}
            >
              Anlegen
            </Button>
            <Button
              xs="100px"
              primary
              onClick={e => {
                e.preventDefault();
                setMode(mode !== "qr" ? "qr" : null);
              }}
            >
              QR-Code
            </Button>
            <Button
              xs="90px"
              default
              onClick={e => {
                e.preventDefault();
                setMode(mode !== "list" ? "list" : null);
              }}
            >
              Liste
            </Button>
          </Grid>
        </Form>
        <div style={{ minHeight: "200px" }}>
          {mode === null && (
            <p style={{ marginTop: "130px" }}>Bitte eine Methode wählen.</p>
          )}
          {mode === "pending" && (
            <p style={{ marginTop: "130px" }}>Ticket wird geladen...</p>
          )}
          {mode === "notFound" && (
            <p style={{ marginTop: "130px" }}>Ticket wurde nicht gefunden.</p>
          )}
          {mode === "error" && (
            <p style={{ marginTop: "130px" }}>
              Ticket konnte nicht geladen werden...
            </p>
          )}
          {mode === "qr" && registration.page === 0 && (
            <div style={{ padding: "16px" }}>
              <QrReader
                delay={500}
                onError={qrError}
                facingMode="rear"
                onScan={qrScan(
                  registration,
                  setRegistration,
                  setMode,
                  props.offline
                )}
                style={{ width: "100%" }}
              />
            </div>
          )}
          {mode === "list" && (
            <List
              setTicket={data => setTicket(data, registration, setRegistration)}
            />
          )}
        </div>
      </div>
      <div name="Daten" icon={faEdit} style={{ textAlign: "left" }}>
        <Form spacing={16}>
          <Radio
            label=""
            value={values.salutation}
            onChange={setValue("salutation", registration, setRegistration)}
            error={getErrorText(errors.salutation)}
            inline
          >
            <RadioItem xs={10 / 3} label="Frau" value="female" />
            <RadioItem xs={10 / 3} label="Herr" value="male" />
            <RadioItem xs={10 / 3} label="Anders" value="other" />
          </Radio>
          <Text
            xs={10}
            md={5}
            inline
            label="Vorname"
            value={values.firstname}
            onChange={setValue("firstname", registration, setRegistration)}
            error={getErrorText(errors.firstname)}
          />
          <Text
            xs={10}
            md={5}
            inline
            label="Nachname"
            value={values.lastname}
            onChange={setValue("lastname", registration, setRegistration)}
            error={getErrorText(errors.lastname)}
          />
          <Text
            xs={10}
            md={5}
            inline
            label="Email"
            value={values.email}
            onChange={setValue("email", registration, setRegistration)}
            error={getErrorText(errors.email)}
          />
          <Text
            xs={10}
            md={5}
            inline
            label="Tel."
            value={values.phone}
            onChange={setValue("phone", registration, setRegistration)}
            error={getErrorText(errors.phone)}
          />
          <Text
            xs={10}
            md={5}
            inline
            label="Adresse"
            value={values.address}
            onChange={setValue("address", registration, setRegistration)}
            error={getErrorText(errors.address)}
          />
          <Number
            xs={5}
            md={2}
            inline
            label="PLZ"
            value={values.plz || ""}
            onChange={setValue("plz", registration, setRegistration)}
            error={getErrorText(errors.plz)}
          />
          <Text
            xs={5}
            md={3}
            inline
            label="Ort"
            value={values.city}
            onChange={setValue("city", registration, setRegistration)}
            error={getErrorText(errors.city)}
          />
          <Select
            xs={10}
            md={5}
            inline
            label="Begleitung"
            value={values.accompany}
            options={[
              { label: "+0", value: 0 },
              { label: "+1", value: 1 },
              { label: "+2", value: 2 },
              { label: "+3", value: 3 },
              { label: "+4", value: 4 }
            ]}
            onChange={setValue("accompany", registration, setRegistration)}
            error={getErrorText(errors.accompany)}
          />
          <Grid spacing={16}>
            <div xs={5} style={{ textAlign: "left" }}>
              <Button
                width="120px"
                danger
                onClick={e => {
                  e.preventDefault();
                  setRegistration(getInitialState());
                }}
              >
                Abbrechen
              </Button>
            </div>
            <div xs={5} style={{ textAlign: "right" }}>
              {pending && (
                <div style={{ display: "inline-block", width: "120px" }}>
                  <PendingButton />
                </div>
              )}
              {!pending && (
                <Button
                  primary
                  width="120px"
                  onClick={e => {
                    e.preventDefault();
                    save(registration, setRegistration, props.name);
                  }}
                >
                  CheckIn
                </Button>
              )}
            </div>
          </Grid>

          <div></div>
        </Form>
      </div>
      <div name="Bieternummer" icon={faGavel}>
        {registration.tendererId &&
          !pending &&
          !registration.unkownError &&
          !registration.success && (
            <div style={{ minHeight: "200px" }}>
              <p className={classes.danger} style={{ marginTop: "130px" }}>
                Bieternummer: <b>{registration.tendererId}</b>
              </p>
            </div>
          )}
        {registration.manuall &&
          !registration.tendererId &&
          !pending &&
          !registration.unkownError &&
          !registration.success && (
            <div style={{ padding: "16px", textAlign: "left" }}>
              <h4>Bitte QR Code eingeben</h4>
              {registration.page === 2 && (
                <Form spacing={16} root={{ left: 0, right: 0 }}>
                  <Number
                    inline
                    label="Bieternummer"
                    value={registration.manuallTendererId || ""}
                    onChange={value => {
                      setRegistration({
                        ...registration,
                        manuallTendererId: value
                      });
                    }}
                    xs="rest"
                  />
                  <Button
                    xs="130px"
                    primary
                    onClick={e => {
                      e.preventDefault();
                      qrScanTendererId(registration, setRegistration)(
                        registration.manuallTendererId
                      );
                    }}
                  >
                    Speichern
                  </Button>
                </Form>
              )}
            </div>
          )}
        {!registration.manuall &&
          !registration.tendererId &&
          !pending &&
          !registration.unkownError &&
          !registration.success && (
            <div style={{ padding: "16px", textAlign: "left" }}>
              <h4>Bitte QR Code Scannen</h4>
              {registration.page === 2 && (
                <QrReader
                  delay={500}
                  onError={qrError}
                  facingMode="rear"
                  onScan={qrScanTendererId(registration, setRegistration)}
                  style={{ width: "100%" }}
                />
              )}
            </div>
          )}
        {!pending && registration.unkownError && !registration.success && (
          <div style={{ minHeight: "200px" }}>
            <p className={classes.danger} style={{ marginTop: "130px" }}>
              Ein Fehler ist aufgetreten!
            </p>
          </div>
        )}
        {pending && !registration.success && (
          <div style={{ minHeight: "200px" }}>
            <p style={{ marginTop: "130px" }}>
              Bieternummer wird gespeichert...
            </p>
          </div>
        )}
        {!pending && registration.success && (
          <div style={{ minHeight: "200px" }}>
            <div style={{ marginTop: "130px" }}>
              <h2>
                Bieternummer
                <br />
                <br />
                {registration.tendererId}
                <br />
                <br />
                gespeichert!
              </h2>
            </div>
          </div>
        )}
        <Form spacing={16}>
          <Grid spacing={16} vertical justify="flex-end" root={{ bottom: 0 }}>
            <div xs={5} style={{ textAlign: "left" }}>
              {!pending && !registration.success && (
                <Button
                  xs="130px"
                  default
                  onClick={e => {
                    e.preventDefault();
                    setRegistration({ ...getInitialState() });
                  }}
                >
                  Abbrechen
                </Button>
              )}
            </div>
            <div xs={5} style={{ textAlign: "right" }}>
              {!registration.tendererId && !pending && !registration.success && (
                <Button
                  danger
                  onClick={e => {
                    e.preventDefault();
                    setRegistration({
                      ...registration,
                      manuall: !registration.manuall
                    });
                  }}
                >
                  {registration.manuall ? "Scannen" : "Manuell"}
                </Button>
              )}
              {registration.tendererId && !pending && !registration.success && (
                <Button
                  danger
                  onClick={e => {
                    e.preventDefault();
                    deleteTendererId(registration, setRegistration);
                  }}
                >
                  Löschen
                </Button>
              )}
              {registration.unkownError && (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    setRegistration({ ...registration, unkownError: false });
                  }}
                >
                  Nochmal
                </Button>
              )}
              {!pending && registration.success && (
                <Button
                  xs="130px"
                  success
                  onClick={e => {
                    e.preventDefault();
                    setRegistration({ ...getInitialState() });
                  }}
                >
                  Abschließen
                </Button>
              )}
            </div>
            <div></div>
          </Grid>
        </Form>
      </div>
    </Stepper>
  );
}
