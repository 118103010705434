import React, { useState } from "react";
import Browser from "./Browser";

import Form from "@nodeme/forms-react/Form";
import Text from "@nodeme/forms-react/Text";
import Button from "@nodeme/forms-react/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";

import { Grid } from "@nodeme/grid-react";
/* const styles = theme => definitions => {
  return {};
}; */

const request = {
  query: {
    event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
  },
  projection: [
    "id",
    "tendererId",
    "salutation",
    "firstname",
    "lastname",
    "email",
    "phone",
    "address",
    "plz",
    "city",
    "accompany"
  ],
  sorting: { f: "createdAt", d: "desc" },
  root: { left: 0, right: 0 },
  limit: 7
};

function Ticket(setTicket) {
  return (reg, index, elements, setElements) => (
    <div
      key={reg.id}
      style={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
      }}
    >
      <h1 xs="rest">
        {reg.lastname} <small>{reg.firstname}</small>
      </h1>
      <Form spacing={8}>
        <Text
          xs={10}
          sm={5}
          md={3}
          label="E-Mail"
          value={reg.email || ""}
          readOnly
        />
        <Text
          xs={10}
          sm="rest"
          label="Addresse"
          value={
            Boolean(reg.address) && Boolean(reg.plz) && Boolean(reg.city)
              ? `${reg.address}, ${reg.plz} ${reg.city}`
              : ""
          }
          readOnly
        />
        <Text
          xs={10}
          sm={5}
          md={3}
          label="Bieternummer"
          value={reg.tendererId || ""}
          readOnly
        />
        <div xs="38px" style={{ marginTop: "18px" }}>
          <Button
            primary
            onClick={e => {
              e.preventDefault();
              setTicket(reg);
            }}
          >
            <FontAwesomeIcon
              style={{ position: "relative", left: "2px" }}
              icon={faEdit}
            />
          </Button>
        </div>
      </Form>
    </div>
  );
}

const List = props => {
  //const classes = buildClasses(styles);
  const [search, setSearch] = useState({ field: "", value: null, timer: null });

  return (
    <div style={{ textAlign: "left" }}>
      <Form spacing={8}>
        <Grid
          spacing={8}
          justify="flex-end"
          vertical
          root={{ top: 32, bottom: 0 }}
        >
          <Text
            xs={10}
            md={5}
            lg={4}
            inline
            label="Suche"
            value={search.field}
            onChange={value => {
              if (search.timer) clearTimeout(search.timer);
              setSearch({
                ...search,
                field: value,
                timer: setTimeout(() => {
                  const toSearch = value.toLowerCase();
                  setSearch({
                    field: value,
                    value: Boolean(value)
                      ? { email: toSearch, lastname: toSearch }
                      : null,
                    timer: null
                  });
                }, 250)
              });
            }}
          />
        </Grid>
      </Form>
      <Browser
        model="Registration"
        query={request.query}
        projection={request.projection}
        sorting={request.sorting}
        limit={request.limit}
        root={request.root}
        search={search.value}
        column={4}
        render={Ticket(props.setTicket)}
      />
    </div>
  );
};

export default List;
